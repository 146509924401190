import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "reactstrap";
import { Button } from "reactstrap";
import axios from "axios";
import validation from '../Validations/Buyer-All.js';
import CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';

const FeatureOne = () => {

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); // State for filtered clients
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");
  const [values, setValues] = useState({
    name: '',
    panno: '',
    contact: '',
    email: '',
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });

  const [errors, setErrors] = useState({});

  const history = useHistory();
  
  const encryptClientId = (clientId) => {
    const encrypted = CryptoJS.AES.encrypt(clientId.toString(), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    return encodeURIComponent(encrypted);
  };

  const handleManageNew = (clientId) => {
    const encryptedClientId = encryptClientId(clientId);
    history.push(`/BuyerManagement/${encryptedClientId}`);
  };

  useEffect(() => {
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyersData')
      .then(response => {
        setClients(response.data);
        setFilteredClients(response.data); // Initialize filtered clients
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    
    const filteredData = clients.filter(client => 
      client.Name.toLowerCase().includes(value) ||
      client.Panno.toLowerCase().includes(value) ||
      client.Contact.toLowerCase().includes(value) ||
      client.Email.toLowerCase().includes(value) 
    );
    
    setFilteredClients(filteredData);
  };

  const handleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleFileChange = (event, imageNumber) => {
    const file = event.target.files[0];
    setValues(prev => ({ ...prev, [`image${imageNumber}`]: file }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));

    if (errors.name === "" && errors.panno === "" && errors.contact === "" && errors.email === "") {
      if (values.image1 && values.image2 && values.image3 && values.image4) {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('panno', values.panno);
        formData.append('contact', values.contact);
        formData.append('email', values.email);
        formData.append('image1', values.image1);
        formData.append('image2', values.image2);
        formData.append('image3', values.image3);
        formData.append('image4', values.image4);

        axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/buyers', formData)
          .then(res => {
            if (res.data === "Exists") {
              setMessageerror("Buyer Email Exists! Please Recheck & Try Again.");
              setMessage("");
            } else {
              setMessage("Operation Success! Record was created successfully!");
              setMessageerror("");
              event.target.reset();

              axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyersData')
                .then(response => {
                  setClients(response.data);
                  setFilteredClients(response.data); // Update filtered clients
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                });
            }
          })
          .catch(err => console.log(err));
      } else {
        setErrors(prev => ({
          ...prev,
          image1: !values.image1 ? "Please select Image 1" : "",
          image2: !values.image2 ? "Please select Image 2" : "",
          image3: !values.image3 ? "Please select Image 3" : "",
          image4: !values.image4 ? "Please select Image 4" : "",
        }));
      }
    }
  };

  const handleDelete = (clientId) => {
    axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com//buyers/${clientId}`)
      .then(response => {
        setClients(clients.filter(client => client.Id !== clientId));
        setFilteredClients(clients.filter(client => client.Id !== clientId)); // Update filtered clients
      })
      .catch(error => {
        console.error('Error deleting client:', error);
      });
  };

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 5 }}>Registered Buyer Profiles</h5>
                <p style={{ color: 'GrayText' }}>The following datalist keeps track of enlisted buyer profiles. Only these profiles to access the auctions via app services.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
           
            <div>
              <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30, backgroundColor: "white" }}>
              <input
              type="text"
              placeholder="Filter Results By Buyer, PAN No, Email Address, Contact No..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Buyer Profile</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>PAN No.</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Contact No.</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Email Address</th>

                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredClients.map(client => (
                      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color:"black" }}>{client.Name}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color:"black" }}>{client.Panno}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color:"black" }}>{client.Contact}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color:"black" }}>{client.Email}</td>

                        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
                          <Button className="btn-warning btn-small" style={{padding:"2px 5px", color:"black"}} onClick={() => handleManageNew(client.Email)}>View</Button> &nbsp;
                          <Button className="btn-danger btn-small" style={{padding:"2px 5px"}} onClick={() => {
                            if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
                              handleDelete(client.Id);
                            }
                          }}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export { FeatureOne };

const Cloud = (props) =>{
    return(
        <svg fill="currentColor" viewBox="0 0 512 512">
            <path d="M472 236.7c3.7-10.2 5.7-21 5.6-31.9-.1-42-28.1-78.9-68.5-90.3C406.5 49.5 352.3-1.3 287.4 0S170.5 54.5 170.5 119.4c0 3.4.2 6.8.5 10.2-27.5-5.3-55.9 1.9-77.5 19.8s-34 44.4-34 72.4c0 3 .2 6.1.5 9.1-35.7 4.4-61.9 35.9-59.7 71.8s31.8 64 67.8 64.2H135c11.3 40.7 40.7 100.9 116.6 144 2.6 1.5 5.8 1.5 8.4 0 75.9-43.1 105.3-103.3 116.6-144h66.9c32.1 0 59.9-22.3 66.7-53.6 6.9-31.3-9-63.2-38.2-76.6zm-105.2 86c-.4 10.9-1.9 21.7-4.6 32.2-.3.8-.5 1.6-.6 2.4-9.2 36.5-34.7 94.4-105.8 136.4-71.1-42-96.6-99.8-105.8-136.4-.1-.8-.3-1.6-.6-2.4-2.6-10.5-4.2-21.3-4.5-32.2V213.3c0-4.7 3.8-8.5 8.5-8.5h204.8c4.7 0 8.5 3.8 8.5 8.5l.1 109.4zm113 12.1c-9.6 9.7-22.6 15.1-36.2 15h-62.9c1.8-9 2.9-18.1 3.1-27.2V213.3c0-14.1-11.5-25.6-25.6-25.6H153.4c-14.1 0-25.6 11.5-25.6 25.6v109.3c.2 9.1 1.3 18.2 3.1 27.2H68.1c-27.3 0-49.8-21.4-51.1-48.7-1.3-27.3 19-50.8 46.2-53.4 4.6 16.3 13.6 31 26 42.5 2.2 2.2 5.4 3 8.4 2.1 3-.9 5.3-3.3 5.9-6.3.7-3-.3-6.2-2.7-8.3-11.6-10.8-19.5-25-22.6-40.6-5.5-27 3.9-54.9 24.6-73.1s49.6-23.9 75.6-15c.2 0 .3.1.5.1.6.1 1.1.2 1.7.2.5.1 1.1.1 1.6 0h.5c.4-.1.7-.3 1-.4.5-.2 1.1-.4 1.6-.6.5-.3.9-.6 1.3-1 .9-.7 1.6-1.6 2-2.6.2-.3.4-.6.5-.9.1-.2 0-.3.1-.5.1-.6.2-1.1.2-1.7.1-.6.1-1.1 0-1.7v-.5c-1.3-6.6-1.9-13.3-2-20 .1-36.6 19.7-70.3 51.4-88.5s70.8-18.1 102.4.3 51.1 52.2 51 88.8c0 .4-.1.7-.1 1.1-.1 15-3.6 29.7-10.2 43.2-2.1 4.2-.3 9.4 3.9 11.4 4.2 2.1 9.4.3 11.4-3.9 6-12.4 9.9-25.8 11.2-39.6 31 10.6 51.9 39.8 52 72.6 0 11.1-2.4 22-7 32.1-.2.3-.4.6-.5 1-7 14.9-18.7 27.1-33.2 34.8-3.4 1.9-5.2 5.8-4.2 9.6s4.4 6.5 8.3 6.5c1.4 0 2.8-.3 4-1 15.3-8.2 27.9-20.6 36.6-35.6 15.3 6.9 26.3 20.9 29.4 37.4 3.1 16.4-2.2 33.3-14 45.2z"></path>
            <path d="M332.6 221.8c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5v83.6c-.1 3.2-2.2 79.5-81.6 133.7-2.6 1.7-4.1 4.6-3.9 7.7.2 3.1 2.1 5.8 4.9 7.2s6.1 1 8.6-.8c86.7-59 89-143.9 89.1-147.7v-83.7c0-9.3-7.7-17-17.1-17zM179 322.7V239h93.9c4.7 0 8.5-3.8 8.5-8.5s-3.8-8.5-8.5-8.5H179c-9.4 0-17.1 7.6-17.1 17.1v83.7c2.4 34.9 14.8 68.4 35.8 96.4 2.8 3.8 8.1 4.7 11.9 1.9s4.7-8.1 1.9-11.9c-18.8-25.3-30-55.2-32.5-86.5z"></path>
            <path d="M313.1 283c-3.3-3.3-8.7-3.3-12.1 0l-63.3 63.3-10-10c-3.3-3.2-8.7-3.2-12 .1-3.3 3.3-3.3 8.6-.1 12l16 16c3.3 3.3 8.7 3.3 12.1 0l69.3-69.3c3.4-3.4 3.4-8.8.1-12.1z"></path>
        </svg>
    )
}

export default Cloud
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Col, Container, Row } from "reactstrap"
import { Button } from "reactstrap";

const FeatureOne = () => {
  const { clientId } = useParams();
  const [decryptedClientId, setDecryptedClientId] = useState('');
  const [file, setFile] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false); // State variable for refreshing table

  useEffect(() => {
    const decryptClientId = (encryptedClientId) => {
      try {
        const decrypted = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptedClientId),
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Decryption failed:', error);
        return 'Invalid Client ID';
      }
    };

    if (clientId) {
      const decryptedId = decryptClientId(clientId);
      setDecryptedClientId(decryptedId);
    }
  }, [clientId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file || !clientId) {
      alert('Please select a file and ensure client ID is available.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      try {
        await axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/excelupload', {
          decryptedClientId,
          data: jsonData
        });
        alert('Excel file was imported successfully!');
        setRefreshTable(true); // Trigger table refresh
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file.');
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const [auctionData, setAuctionData] = useState([]);

  useEffect(() => {
    const fetchAuctionData = async () => {
      try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionData/${decryptedClientId}`);
        setAuctionData(response.data);
      } catch (error) {
        console.error('Error fetching auction data:', error);
      }
    };
    if (decryptedClientId) {
      fetchAuctionData();
    }
  }, [decryptedClientId, refreshTable]); // Include refreshTable in dependency array

  var bgColors = { "light": "white",
  "greyish": "#2F2F2E"
};

const [clients, setClients] = useState([]);
const handleDelete = (clientId) => {
  // Make an API call to delete the client
  axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/removeauctiondata/${clientId}`)
    .then(response => {
      // If deletion is successful, update the clients array to remove the deleted client
      setClients(clients.filter(client => client.Id !== clientId));
      setRefreshTable(true);
    })
    .catch(error => {
      console.error('Error deleting client:', error);
      // Handle error, such as displaying an error message
    });
};

  return (

    <div style={{paddingTop:90}}>
    <Container>
    <Row className='align-items-center'>
            <Col xl="12">
                <div>
                    <div style={{marginBottom:10}}>
                    
                   <h5 style={{fontWeight:"bold",color:"brown",marginBottom:5}}>Upload Bulk Data - BTW{decryptedClientId}</h5>
                   <p style={{color:'GrayText'}}>The following control helps in uploading bulk records to existing auctions using excel file uploader.</p>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md="12">
                <div>
                <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
               
                  
                   <div className="is-alter">
        <div className="form-group">
            <label className="form-label" htmlFor="company">
              Please Select a valid Excel File 
            </label>
            <div className="form-control-wrap">
            <input type="file"  className="form-control-lg form-control" onChange={handleFileChange} />
             
            </div>
          </div>
        
       
     
          <div className="form-group">
          <button color="primary" size="lg" className="btn-primary btn-block" onClick={handleFileUpload}>Upload Excel File</button>
            
          </div>
        </div>
                  
                    </div>
                  
                   
                   
                </div>
            </Col>
        
        </Row>
        
    </Container>
</div>


   
     
    
      
    
      
   
  );
};

export { FeatureOne };

import React from "react"
import { Col, Row, Container } from "reactstrap"
import { Logo } from "../../components/logo/Logo"
import Footer from "../../layout/footer/Footer"

import LogoDrak2x from '../../Resc/header-black.png'
import LogoLight2x from '../../Resc/header-white.png'   

import { SocialIcon } from "../../components/icon/Icon"

// import { LinkInline } from "../../components/styledList/StyledList"
import { Link } from "react-router-dom";

import { SocialIconOne } from './FooterData'


const FooterOne = (props) =>{
    return(
        <Footer className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="g-3 align-items-center justify-content-md-between py-4 py-md-5">
                    <Col md="3">
                        <div className="footer-logo">
                            <Logo to="/"
                            dark ={LogoDrak2x}
                            light ={LogoLight2x}
                            />
                        </div>
                    </Col>
                    <Col md="9" className="d-flex justify-content-md-end">

                        {/* <LinkInline className="gx-4" data={LinkData} /> */}

                <Link className="gx-4" style={{marginLeft: 0, marginRight: 20, color: "whitesmoke"}} to={`${process.env.PUBLIC_URL}/privacy`}>
                  Privacy Policy
                </Link>

                <Link className="gx-4" style={{marginLeft: 0, marginRight: 20, color: "whitesmoke"}} to={`${process.env.PUBLIC_URL}/terms`}>
                  Terms &amp; Conditions
                </Link>

                <Link className="gx-4" style={{marginLeft: 0, marginRight: 0, color: "whitesmoke", fontWeight: "bolder"}} to={`${process.env.PUBLIC_URL}/login`}>
                  Employee Extranet
                </Link>

                    </Col>
                </Row>
                <hr className="hr border-light mb-0 mt-n1"></hr>
                <Row className="g-3 align-items-center justify-content-md-between py-4">
                    <Col md="8">
                        <div className="text-base">
                            Copyright &copy; 2024 BidToWin. Official IT Partner 
                            <a className="text-base fw-bold" href="https://lyndata.com" target="_blank" rel="noreferrer"> Lyndata Systems LLP </a>
                        </div>
                    </Col>
                    <Col md="4" className="d-flex justify-content-md-end">
                        <SocialIcon data={SocialIconOne} />
                    </Col>
                </Row>
            </Container>
        </Footer>
    )
}

export default FooterOne

import React from 'react'
import { Col, Container, Row } from "reactstrap"
import { ImageBlock } from '../../components/images/Images'
import { Section } from '../../layout/section/Section'
import { Paragraph, TextBlock, TitleH2 } from '../../components/textBlock/TextBlock'
import { Link } from '../../components/button/Button'
import { OutlinedChecked } from '../../components/styledList/StyledList'
import {FeatureOneList} from './FeatureData'

import video1 from '../../Resc/Video.mp4'
import thumbnail from '../../Resc/Thumbnail.png'
import image1 from '../../Resc/about.jpg'


const FeatureOne = (props) =>{

    

    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className='justify-content-between align-items-center'>
                    <Col lg="6">
                        <ImageBlock className="img-block-s1 left">
                            <video src={video1} style={{width: '100%', height: 'auto'}} controls="controls" poster={thumbnail} autoPlay={false} />
                        </ImageBlock>
                    </Col>
                    <Col lg="5">
                        <TextBlock className="pe-xl-5">
                            <TitleH2>Discover Extraordinary Treasures at our Auctions</TitleH2>
                            <Paragraph>Explore our curated collection of auction vehicles, ranging from fine art and rare collectibles to luxury vacations and once-in-a- lifetime experiences.</Paragraph>
                            <OutlinedChecked className="list-lg list-success list-checked-circle outlined" data={FeatureOneList} />
                            <ul className="btns-inline">
                                <li><Link to={`${process.env.PUBLIC_URL}/registration`} className="btn-lg btn-primary">Register With Us</Link></li>
                            </ul>
                        </TextBlock>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

const FeatureOneAlt = (props) =>{
    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className='flex-row-reverse justify-content-between align-items-center'>
                    <Col lg="5">
                        <ImageBlock className="img-block-s1 right">
                            <img src={image1} alt="/" />
                        </ImageBlock>
                    </Col>
                    <Col lg="6">
                        <TextBlock>
                            <TitleH2>What sets us Apart?</TitleH2>
                            <Paragraph>At BidToWin Auction, we understand that every bid represents a story, a passion, and a dream. That's why we're committed to creating an environment where bidders feel empowered, informed, and inspired to participate. With our user-friendly platform, transparent bidding process, and curated selection of vehicles, we strive to make every auction an unforgettable experience.</Paragraph>
                            <Paragraph><b>Our Mission</b>: Our mission at BidToWin Auction is simple - to provide a dynamic and accessible auction experience for everyone. Whether you're a seasoned collector, a thrill-seeking bidder, or someone looking for a one-of-a-kind gift, we're here to connect you with extraordinary vehicles and unforgettable opportunities.</Paragraph>
                            <ul className="btns-inline">
                                <li><Link to={`${process.env.PUBLIC_URL}/registration`} className="btn-lg btn-primary">Get Onboard Now!</Link></li>
                            </ul>
                        </TextBlock>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export {FeatureOne, FeatureOneAlt}

import React, {useState} from "react";

import Logo from "./Resc/header-white.png";
import LogoDark from "./Resc/header-black.png";
import background from "./Resc/regbag.jpg";

import PageContainer from "./layout/page-container/PageContainer";
import Head from "./layout/head/Head";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import validation from './Validations/Self-Registration-All.js';

const Register = () => {

const [message, setMessage] = useState("");
const [values, setValues] = useState({
  company: '',
  name: '',
  contact: '',
  email: ''
})

const [errors, setErrors] = useState({})

const handleInput = (event) => {
  setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))
}

const handleSubmit = (event) => 
  {
    event.preventDefault();
    setErrors(validation(values));

    if(errors.company === "" && errors.name === "" && errors.contact === "" && errors.email === "")
      {
        axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/registration', values)
        .then(res => {
          setMessage("Request received successfully!");
          console.log("success");
          event.target.reset();
        })
        .catch(err => console.log(err));
      }
  }
  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
      <div style={{backgroundImage: `url(${background})`, height: "130vh"}}>
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <div className="card-preview card-bordered" style={{backgroundColor: "white"}}>
            <div className="card-inner card-inner-lg">
                <div className="nk-block-head">
                  <div className="nk-block-content">
                    {message && <p style={{padding:5, backgroundColor:"green", color:"white", borderRadius:2}}>{message}  <Link to="/"><strong style={{color:"white", textDecoration:"underline", float:"right"}}>Return Home</strong></Link></p>}
                    <h5 className="nk-block-title page"  style={{fontSize: 17, fontWeight: "bolder"}}>SELF REGISTRATION DESK</h5>
                    <div className="nk-block-des">
                      <p>Use the following form to <span style={{textDecoration:"underline", color:"black", fontWeight:"bold"}}>request an account creation with BidToWin</span>. All registration requents are handled by approved BidToWin Account Managers.</p>
                    </div >
                  </div>
                </div>
                <form className="is-alter" action="" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="form-label" htmlFor="company">
                      Company / Business / Firm
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Enter Business Name"
                        className="form-control-lg form-control"
                      />
                       {errors.company && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.company}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Contact Person
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Your Name"
                        className="form-control-lg form-control"
                      />
                      {errors.name && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.name}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="contact">
                      IN Contact No.
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="contact"
                        name="contact"
                        placeholder="Enter 10 Digit Contact No." maxLength="10"
                        className="form-control-lg form-control"
                      />
                       {errors.contact && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.contact}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="email">
                        Communication Email Address
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        bssize="lg"
                        id="email"
                        name="email"
                        className="form-control-lg form-control"
                        placeholder="Enter Your Email Address"
                      />
                       {errors.email && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.email}</span>}
                    </div>
                  </div>
             
                  <div className="form-group">
                    <Button type="submit" color="primary" size="lg" className="btn-block">
                      Raise Registration Request
                    </Button>
                  </div>
                </form>
                <div className="form-note-s2 text-center pt-4">
                  {" "}
                  Already have an account?{" "}
                  <a
  href="https://play.google.com/store/apps/details?id=com.lyndatasystemsofficial.Bidtowin&hl=en"
  target="_blank"
  rel="noreferrer"
  
>
  
    Continue To App

</a>
                </div>
              
              </div>
            </div>
          </div>
      </div>
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;

import React from 'react'
import { Container, Row } from "reactstrap"
import { Section } from '../layout/section/Section'

import { TextBlock } from '../components/textBlock/TextBlock'


const FeatureOne = (props) =>{

    

    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className='justify-content-between align-items-center'>
                    
                    <div lg="11" style={{marginTop: "-14%", marginBottom: 30}}>
                        <TextBlock className="pe-xl-5">

<h5>Terms & Conditions</h5>                            
<p>This document is an electronic record in terms of Information Technology Act, 2000 and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>

<p>Welcome to www.Bidtowin.in. If you continue to browse and use the Website / App (hereinafter, collectively referred to as "Website") you are agreeing to comply with and be bound by the following terms and conditions of use, including any future amendments (“Terms of Use”), which together with our Privacy Policy govern our relationship with you in relation to this Website (“Agreement”).</p>

<h6>General</h6>

<p>For the purpose of the User Agreement, Registered User / and wherever the context so require 'You' shall mean any natural or legal person who has agreed to become a member of the Website by providing Registration Data (as defined hereinafter) while registering on the Website using the computer systems of the Website and accepted this electronic version / electronic record of the User Agreement and has allocated himself a unique identification user name ("User ID" and "Password").</p>

<p>As a new Registered User, this User Agreement shall be effective and binding upon your 'acceptance'. 'Acceptance' shall mean your affirmative action in clicking on 'check box' and on the 'continue button' as provided on the registration page. If you do not agree or are not willing to be bound by the terms and conditions of this User Agreement, please do not click on the "check box" and on the "Continue" button and do not seek to obtain access to or otherwise use the Website.</p>

<p>You may not modify copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from the Website.</p>

<p>As a condition of your use of the “Website”, you pledge that you will not use the site for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Website in any manner which could damage, disable, overburden, impair, or interfere with any other party's use and enjoyment of the Website. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Website. You agree, and are hereby put on notice, that you are prohibited from posting or transmitting to or from this Website any unlawful, threatening, libellous, defamatory, inflammatory, pornographic, or profane material or any other material that could give rise to any civil or criminal liability under law. You are solely responsible for the content of any comments you make.</p>

<h6>Amendments</h6>
<p>Bidtowin.in. (hereinafter referred to as “BIDTOWIN”) may amend this User Agreement and/or the Bidtowin Privacy Policy at any time by posting a revised version on the Website. All updates and amendments shall be notified to You via Bidtowin Website's Announcement Board (hereinafter referred to as an "AB Post"). The revised version will be effective at the time BIDTOWIN posts it on the Website / AB Post. You are advised to regularly check for any amendments or updates to the terms and conditions contained in this User Agreement and in the Bidtowin.com Privacy Policy.</p>

<h6>Membership Eligibility</h6>
<p>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un- discharged insolvents etc. are not eligible to use the Website. If you are a minor i.e. under the age of 18 years, you shall not register as a member of the Website. BIDTOWIN reserves the right to terminate your membership and refuse to provide you with access to the Website if it is brought to BIDTOWIN notice or if it is discovered that you are under the age of 18 years.</p>

<h6>Business Entity Registration</h6>
<p>If you are registering as a business entity, you represent that you are duly authorized by the business entity to accept this User Agreement and you have the authority to bind that business entity to this User Agreement and Privacy Policy.</p>

<h6>Account & Registration Obligations</h6>
<p>If you use the Website, you are responsible for maintaining the confidentiality of your User ID and Password and for restricting access to your computer, computer system and computer network, and you are responsible for all activities that occur under your User ID and Password. In addition, you agree to provide true, accurate, current and complete information into the Website.</p>
 
<h6>Electronic Communication</h6>
<p>When You use the Website, You agree and understand that You are communicating with BIDTOWIN through electronic records and You consent to receive communications via electronic records from BIDTOWIN periodically and as and when required. BIDTOWIN will communicate with You by email or by an AB Post notice or electronic records on the Bidtowin.com Website which will be deemed adequate service of notice / electronic record.</p>

<h6>Fees & Services</h6>
<p>Membership on the Website is available on the payment of prescribed fee structure and meeting other conditions as described in the User Agreement. All fees are non-refundable. BIDTOWIN may at its sole discretion introduce new services and modify some or all of the existing services offered on the Website. In such an event BIDTOWIN reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be.</p>

<p>Non-payment: BIDTOWIN reserves the right to issue a warning, temporarily /indefinitely suspend or terminate your membership of the Website and refuse to provide you with access to the Website in case of non-payment of fees by You to BIDTOWIN, BIDTOWIN also reserves the right to take legal action in case of non-payment of fees by You to BIDTOWIN.</p>

<p>Registered user understands and agrees that the Auctioneer may at its discretion shall have right to forfeit the entire Security Deposit or part thereof and adjust it against any charges and dues which are outstanding and payable by the Bidder to the Auctioneer or Seller including but not limited to non-payment of the amount of Total Price to the Seller within the prescribed time period, refusal to honour the Total Price including the Winning Bid, non-payment of Success Fee to the Auctioneer or violation of any other term of this Agreement resulting in any loss or damage to the Auctioneer or Seller. The Bidder hereby expressly agrees and authorise the Auctioneer for adjustment of such outstanding dues and charges payable by Bidder to the Auctioneer or Seller.</p>

<p>Unless otherwise stated, all fees shall be quoted in Indian Rupees</p>

<h6>Taxes</h6>
<p>You are responsible for paying all fees associated with the use of the Website and you agree to bear any and all applicable taxes, charges, cesses etc. levied thereon.</p>

<h6>Use Of Website</h6>
<p>You understand and agree that BIDTOWIN and the Website merely provides services to its Registered Users. All items advertised / listed and the contents therein are advertised and listed are third party user generated contents. BIDTOWIN neither initiates the transmission nor selects the sender and receiver of the transmission nor selects nor modify the information contained in the transmission. BIDTOWIN has no control over the third party user generated contents.</p>

<p>You agree, undertake and confirm that your use of the Website shall be strictly governed by the following binding principles: "Your Information" is defined as any information you provide on the Website. You are solely responsible for Your Information, and we act only as a passive conduit for your online distribution and publication of Your Information. Your Information (or any items listed) :</p>
<p>•	shall not be false, inaccurate or misleading;
<br/>•	shall not be fraudulent or involve the sale of counterfeit or stolen items;
<br/>•	shall not infringe any third party's intellectual property, trade secret or other proprietary rights or rights of publicity or privacy;
<br/>•	shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force. Without prejudice to the generality of the above, BIDTOWIN does not permit transactions in the following items including but not limited to categories as stated in this document.
<br/>•	shall not contain any viruses, Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
<br/>•	shall not create liability for us or cause us to lose (in whole or in part) the services of our ISPs or other suppliers; and</p>

<p>Solely to enable BIDTOWIN to use the information you supply us with, so that we are not violating any rights you might have in Your Information, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub- licensable (through multiple tiers) right to exercise the copyright, publicity, and database rights (but no other rights) you have in Your Information, in any media now known or not currently known, with respect to Your Information. BIDTOWIN will only use Your Information in accordance with this Agreement. In the event that any transaction.</p>
 
<p>transaction or information relating to any item, good or service which is in violation of this User Agreement or applicable law comes to your knowledge, you shall take all steps to inform BIDTOWIN of the same. You may contact BIDTOWIN from Contact Us</p>

<p>You shall comply with all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations (including, but not limited to Sales Tax/ VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty, Local Levies) regarding your use of the Website. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</p>

<p>From time to time, you shall be responsible for providing information relating to the items or services. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or over emphasize the attributes of such items or services so as to mislead other Users in any manner.</p>

<p>You shall not attempt to or circumvent or manipulate our Fee structure, the billing process or Fees owed to BIDTOWIN.</p>

<h6>Platform For Communication</h6>

<p>BIDTOWIN is not and cannot be a party to or control in any manner any interaction between two Users of the Website or any other third party. Consequently:</p>

<p>•	BIDTOWIN does not make any representation or Warranty as to the attributes (such as quality, worth, marketability, etc.) of the items or services on the Website. BIDTOWIN accepts no liability for any errors or omissions, whether on behalf of itself or third parties.

<br/>•	BIDTOWIN does not make any representation or warranty as to the attributes (such as legal title, creditworthiness, identity, etc.) of any information on the Website. You are advised to independently verify the bona fides of any particular User that you choose to deal with on the Website and use your best judgment in that behalf.

<br/>•	BIDTOWIN is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the two parties. At no time shall any right, title or interest over the items vest with BIDTOWIN nor shall BIDTOWIN have any obligations or liabilities in respect of such contract. BIDTOWIN is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of items which are out of stock, back ordered or otherwise unavailable. At no time shall any right, title or interest over the items vest with BIDTOWIN nor shall BIDTOWIN have any obligations or liabilities in respect of such contract. BIDTOWIN is not responsible for unsatisfactory or delayed performance of services or damages or delays.</p>

<h6>Breach</h6>
<p>Without limiting other remedies, BIDTOWIN may limit your activity, immediately temporarily/ indefinitely suspend or terminate your membership, and/or refuse to provide you with access to the Website in the event, but not limited to:</p>
<p>•	If you breach the User Agreement or the documents, agreements rules, policies, terms and conditions these incorporate by reference;
<br/>•	If BIDTOWIN is unable to verify or authenticate any information you provide; or
<br/>•	If it is believed that your actions may cause legal liability for you, other Users or BIDTOWIN
<br/>•	BIDTOWIN may at any time at its sole discretion reinstate suspended Users. A User that has been indefinitely suspended may not register or attempt to register with BIDTOWIN or use the Website in any manner whatsoever until such time that such User is reinstated by BIDTOWIN. Notwithstanding the foregoing, if you breach the User Agreement or the documents it incorporates by reference, BIDTOWIN reserves the right to recover any amounts due and owing by you to BIDTOWIN and to take strict legal action including but not limited to a referral to the appropriate police or other authorities for initiating criminal or other proceedings against you.</p>

<h6>No Warranty</h6>
<p>BIDTOWIN and its suppliers, affiliates and service providers provide the Website "as is" and without any Warranty or condition, express, implied or statutory and specifically disclaim any implied Warranties of title, merchantability, fitness for a particular purpose and non-infringement. You expressly agree that your use of the Website is at your risk.</p>
 
<h6>Limitation Of Liability</h6>
<p>In no event shall BIDTOWIN or its suppliers, affiliates and service providers be liable for any direct, indirect, incidental, special, incidental, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website, its services or this agreement (however arising, including negligence).</p>

<p>BIDTOWIN its associates, affiliates and service providers and technology partners make no representations or Warranties about the accuracy, reliability, completeness, and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through the use of the Website or that the operation of the Website or will be error free and/or uninterrupted. Consequently, BIDTOWIN assumes no liability whatsoever for any monetary or other damage suffered by you on account of:</p>

<p>•	The delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Website.
<br/>•	Any interruption or errors in the operation of the Website. You expressly understand and agree that BIDTOWIN. shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if BIDTOWIN has been advised of the possibility of such damages).
<br/>•	The content, accuracy and reliability of the material provided by the other users or any other individual.</p>

<h6>Proprietary Rights</h6>
<p>The information contained in this Website including all images, illustrations, designs, photographs, video clips, writings and other materials that appear herein are copyrights, trademarks, trade dress or other intellectual property owned, controlled, or licensed (all of which, collectively, “Contents”) by the Website or its subsidiaries and affiliates or are the property of their respective owners. The name “Bidtowin.com” and its logos are trademarks of the Website.</p>

<h6>Representation & Warranties - BidToWin</h6>
<p>The Website and/or its respective suppliers, affiliates, associates, representatives make no representations about the suitability, reliability, availability, timeliness and accuracy of the information, products and services contained on the Website for any purpose. All such information, products offered, services offered etc. are provided on an "as is where is" basis without warranty of any kind. Website and/or its respective suppliers, associates, affiliates hereby disclaim all warranties and conditions with regard to the information, products, services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement. Nor do they guarantee that this information will be error free or continuously available, or that the service will be free of viruses or other harmful components. The information generated by the Website is believed to be accurate, but the Website and its suppliers do not warrant or guarantee such accuracy. The Website and its suppliers do not authorize the use of such information for any purpose other than your personal use, and prohibit to the maximum extent allowable the resale, redistribution, and use of this information for commercial purposes.</p>

<p>In no event shall the Website be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including without limitation, damages for loss of use arising out of or in any way connected with the use or performance of the Website, with the delay or inability to use the Website or related services, the provision or failure to provide services, or for any information, products, services and related graphics obtained through the Website, or otherwise arising out of the use of the Website, whether based on contract, tort, negligence, strict liability or otherwise, even if the Website or any of its suppliers/affiliates has been advised of the possibility of damages. If you are dissatisfied with any portion of the Website or with any of these terms of use, your sole and exclusive remedy is to discontinue using the Website.</p>

<p>The Website shall not be responsible for, and does not guarantee the performance of, any goods or services purchased through the Website</p>

<h6>Representation & Warranties - User</h6>
<p>You shall not upload from, post or transmit or distribute or otherwise publish through the Site any materials which restrict any other user from using the Site and /or are unlawful, threatening, abusive, defamatory, vulgar, offensive, pornographic, or indecent and /or constitute or contain false or misleading indications of origin or statements of fact. You further represents and warrants BIDTOWIN that all the information provided by you about yourself are true and accurate; and you shall be responsible for all actions taken by you or by any other individual through your ID to whom you have given authorization to use.</p>
 
<h6>Copyright & Trademarks</h6>
<p>Unless otherwise stated, copyright and all intellectual property rights in all material presented on the site including but not limited to text, graphics, button icons, images, audio clips, digital downloads, data compilations, and software, trademarks and logos appearing on the site are the property of the BIDTOWIN, its parent, affiliates and associates and are protected under applicable Indian and international laws. You agree not to use any trademark or logo or other proprietary information of the BIDTOWIN.</p>

<h6>Communication</h6>
<p>You agree that BIDTOWIN can and may communicate with you via phone calls, SMS, E-Mails and/or any other method & BIDTOWIN will not be liable to you and/or any other authority for such an act. If your number is registered with any Do Not Call (DNC) and/or Do Not Disturb (DND) service you hereby authorize BIDTOWIN to communicate with you via telephone calls, SMS, E-Mails and/or any other method and You will not in any case hold BIDTOWIN liable for any communication in this regard.</p>

<h6>Indemnity</h6>
<p>You shall indemnify and hold harmless BIDTOWIN and (as applicable) BIDTOWIN parent, subsidiaries, affiliates, third- parties and their respective officers, directors, agents, employees, suppliers, service partners, agents and consultants from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of your breach of this Agreement, or the documents they incorporates by reference, or your violation of any law, rules or regulations or the rights of a third party.</p>

<h6>Relationship, Notice & Severability</h6>
<p>None of the provisions of the User Agreement shall be deemed to constitute a partnership or agency between you and BIDTOWIN and you shall have no authority to bind BIDTOWIN in any manner whatsoever.</p>
<p>Except as explicitly stated otherwise, any notices shall be given by registered postal mail on behalf of BIDTOWIN to Bidtowin Attn: Legal Department, Bidtowin at Bidtowin.in, Himalaya Enclave Phase 3,  101 Vrindavan Colony, Near SGPGI Trauma Centre, Lucknow 226029, India. (in the case of Bidtowin.in.) or to the email address you provide to us during the registration process (in your case). Notice shall be deemed given 24 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the address provided to us during the registration process. In such case, notice shall be deemed given 3 days after the date of mailing.</p>

<p>If any clause of the User Agreement shall be deemed invalid, void or for any reason unenforceable, such clause shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the User Agreement.</p>

<h6>Arbitration</h6>
<p>If any dispute arises between you and BIDTOWIN during your use of the Website or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of the User Agreement and Seller Terms and Conditions or the documents they incorporates by reference, the dispute shall be referred to a sole Arbitrator who shall be an independent and neutral third party identified by BIDTOWIN. The place of arbitration shall be Mumbai. The Arbitration & Conciliation Act, 1996, shall govern the arbitration proceedings. The arbitration proceedings shall be in the English language and each party shall pay their own costs.</p>

<h6>Governing Law</h6>
<p>The User Agreement Terms and Conditions or the documents they incorporate by reference shall be governed and construed in accordance with the laws of India</p>

<h6>Entire Agreement</h6>
<p>Unless otherwise specified herein, the Agreement constitutes the entire agreement between the User and the Website and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the User and the Website. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>

                            
                        </TextBlock>
                    </div>
                </Row>
            </Container>
        </Section>
    )
}



export {FeatureOne}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Col, Container, Row } from "reactstrap";
import { Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import validation from '../Validations/Auction-All.js';

const FeatureOne = () => {
  const { clientId } = useParams();
  const [decryptedClientId, setDecryptedClientId] = useState('');
  const [auctionData, setAuctionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");
  const [enddate, setEnddate] = useState("");
  const [endtime, setEndtime] = useState("");
  const [clientName, setClientName] = useState('');
  
  const history = useHistory();
  
  const bgColors = {
    "light": "white",
    "greyish": "#2F2F2E"
  };

  useEffect(() => {
    const decryptClientId = (encryptedClientId) => {
      try {
        const decrypted = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptedClientId),
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Decryption failed:', error);
        return 'Invalid Client ID';
      }
    };

    if (clientId) {
      const decryptedId = decryptClientId(clientId);
      setDecryptedClientId(decryptedId);
    }
  }, [clientId]);

  useEffect(() => {
    const fetchAuctionData = async () => {
      try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionDataList/${decryptedClientId}`);
        setAuctionData(response.data);
        setFilteredData(response.data); // Initialize filtered data
      } catch (error) {
        console.error('Error fetching auction data:', error);
      }
    };

    if (decryptedClientId) {
      fetchAuctionData();
    }
  }, [decryptedClientId]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredData(
      auctionData.filter(
        auction =>
          auction.MAKE.toLowerCase().includes(lowercasedQuery) ||
          auction.MODEL.toLowerCase().includes(lowercasedQuery) ||
          auction.VARIENT.toLowerCase().includes(lowercasedQuery) ||
          auction.REG_NO.toLowerCase().includes(lowercasedQuery) ||
          auction.YOM.toString().includes(lowercasedQuery) ||
          auction.AGREMENT_NUMBER.toLowerCase().includes(lowercasedQuery) 
      )
    );
  }, [searchQuery, auctionData]);

  const handleManage = (auctionId) => {
    const encryptedClientId = CryptoJS.AES.encrypt(auctionId.toString(), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    history.push(`/VehicleData/${encodeURIComponent(encryptedClientId)}`);
  };

  const handleDelete = async (auctionId) => {
    try {
      await axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/removeauctiondata/${auctionId}`);
      setFilteredData(filteredData.filter((auction) => auction.Id !== auctionId));
    } catch (error) {
      console.error("Error deleting auction:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const values = {
      enddate,
      endtime,
      decryptedClientId
    };
    setErrors(validation(values));

    if (errors.enddate === "" && errors.endtime === "") {
      setIsSubmitting(true);
      
      axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/auctionupdate', values)
        .then(res => {
          if (res.data === "Success") {
            setMessage("Operation Success! Auction was updated successfully!");
            setMessageerror("");
            event.target.reset();
            setIsSubmitting(false);
          } else {
            setMessageerror("Sorry! An error occurred please try again later.");
            setMessage("");
            setIsSubmitting(false);
          }
        })
        .catch(err => {
          console.error("Axios request failed:", err.res ? err.res.data : err.message);
          setMessageerror("An error occurred. Please try again later.");
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getClientName/${decryptedClientId}`);
        setClientName(response.data.name);
      } catch (error) {
        console.error('Error fetching client name:', error);
      }
    };

    if (decryptedClientId) {
      fetchClientName();
    }
  }, [decryptedClientId]);

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        {/* Search Input */}
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 5 }}>
                  Auction Management - BTW{decryptedClientId} - {clientName}
                </h5>
                <p style={{ color: 'GrayText' }}>The following view provides quick details of all vehicles listed within an auction.</p>
                {message && <p style={{ padding: 5, backgroundColor: "green", color: "white", borderRadius: 2 }}>{message}</p>}
                {messageerror && <p style={{ padding: 5, backgroundColor: "red", color: "white", borderRadius: 2 }}>{messageerror}</p>}
                
              
              </div>
            </div>
          </Col>
        </Row>

        {/* Form for Updating Auction */}
        <form className="is-alter" onSubmit={handleSubmit}>
          <Row>
            <Col md="4">
              <div style={{ padding: 5, border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => setEnddate(e.target.value)}
                      type="date"
                      id="enddate"
                      name="enddate"
                      min={new Date().toISOString().split('T')[0]}
                      placeholder="Select End Date"
                      className="form-control-lg form-control"
                    />
                    {errors.enddate && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.enddate}</span>}
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div style={{ padding: 5, border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => setEndtime(e.target.value)}
                      type="time"
                      id="endtime"
                      name="endtime"
                      placeholder="Select End Time"
                      className="form-control-lg form-control"
                    />
                    {errors.endtime && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.endtime}</span>}
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <div style={{ padding: 5, border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
                  <Button type="submit" disabled={isSubmitting} color="primary" size="lg" className="btn-block">
                    {isSubmitting ? 'Please Wait...' : 'Update Auction'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>

        {/* Table with Filtered Data */}
        <Row>
          <Col md="12">
            <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
                  {/* Search Bar */}
                  <input
                  type="text"
                  placeholder="Filter Results By Make, Model, Varient, Reg No, YOM, Agreement No..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                />
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Vehicle</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Reg. No. / YOM</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Agreement No.</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Bid Status</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Target Price</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "teal", fontWeight: "normal", fontSize: "0.9rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((auction) => (
                      <tr key={auction.Id} style={{ border: '1px solid #dddddd' }}>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{auction.MAKE} {auction.MODEL} {auction.VARIENT}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{auction.REG_NO} - {auction.YOM}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{auction.AGREMENT_NUMBER}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{auction.BASE_PRICE_RESERVE_PRICE} / {auction.BID_START_PRICE}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{auction.TARGET_PRICE}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
                          <Button className="btn-warning btn-small" style={{ padding: "2px 5px", color: "black" }} onClick={() => handleManage(auction.Id)}>View / Manage</Button> &nbsp;
                          <Button className="btn-danger btn-small" style={{ padding: "2px 5px" }} onClick={() => {
                            if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
                              handleDelete(auction.Id);
                            }
                          }}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { FeatureOne };

 import React from 'react';
 import ReactDOM from 'react-dom';
 import App from './App';
 import './assets/scss/dashlite.scss';

 ReactDOM.render(
   <React.Fragment>
     <App />
   </React.Fragment>,
   document.getElementById('root')
 );


import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap"
import { Button } from "reactstrap";
import axios from "axios";
import validation from '../Validations/Category-Registration-All.js';


const FeatureOne = () =>{

  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getCategoriesData')
      .then(response => {
        // Store the fetched data in the state
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Run this effect only once when the component mounts

    const [message, setMessage] = useState("");
    const [messageerror, setMessageerror] = useState("");
    const [values, setValues] = useState({
      company: '',
      name: ''
    })
    
    const [errors, setErrors] = useState({})
    
    const handleInput = (event) => {
      setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))
    }
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = (event) => 
      {
        event.preventDefault();
        setErrors(validation(values));
    
        if(errors.company === "")
          {
            setIsSubmitting(true); // Disable the button immediately
            axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/categories', values)
            .then(res => {

              if(res.data === "Exists")
                {
                  setMessageerror("Category Exists! Please Recheck & Try Again.");
                  setMessage("");
                  setIsSubmitting(false); 
                }
                else
                {
                  

                  // Fetch data from the API endpoint
                  axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getCategoriesData')
                  .then(response => {
                    // Store the fetched data in the state
                    setClients(response.data);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error);
                  });

                  setMessage("Operation Success! Record was created successfully!");
                  setMessageerror("");
                  event.target.reset();
                  setIsSubmitting(false); 
                }
              
            })
            .catch(err => console.log(err));
          }
      }

  var bgColors = { "light": "white",
  "greyish": "#2F2F2E"
};

const handleDelete = (clientId) => {
  // Make an API call to delete the client
  axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/categories/${clientId}`)
    .then(response => {
      // If deletion is successful, update the clients array to remove the deleted client
      setClients(clients.filter(client => client.Id !== clientId));
    })
    .catch(error => {
      console.error('Error deleting Category:', error);
      // Handle error, such as displaying an error message
    });
};

    
    return(
        <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:0}}>Create / Manage Categories</h5>
                           <p style={{color:'GrayText'}}>The following control helps in creating / managing new / existing category records.</p>
                           
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="5">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        {message && <p style={{padding:5, backgroundColor:"green", color:"white", borderRadius:2}}>{message}</p>}
                        {messageerror && <p style={{padding:5, backgroundColor:"red", color:"white", borderRadius:2}}>{messageerror}</p>}
                           <h6 style={{fontWeight:"bold",color:"black"}}>A. Create New Record</h6>
                           <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                           <p style={{marginBottom:"0.5rem"}}>Use the following form to create new Category record.</p>
                         
                           <form className="is-alter" action="" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="form-label" htmlFor="company">
                      Category Title 
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Enter Category Name"
                        className="form-control-lg form-control"
                      />
                       {errors.company && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.company}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Comments (Optional)
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Comments [If Any]"
                        className="form-control-lg form-control"
                      />
                    </div>
                  </div>
               
             
                  <div className="form-group">
                    <Button type="submit" disabled={isSubmitting} color="primary" size="lg" className="btn-block">
                    {isSubmitting ? 'Please Wait...' : 'Create Record'}
                    </Button>
                  </div>
                </form>
                          
                            </div>
                          
                           
                           
                        </div>
                    </Col>
                    <Col  md="7">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30,backgroundColor:bgColors.light}}>
                        <h6 style={{fontWeight:"bold",color:"black"}}>B. Manage Existing Records</h6>
                        <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                        <p style={{marginBottom:"0.5rem"}}>Use the following datalist to review / delete existing records.</p>

                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Client Name / Title</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Comments [If Any]</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clients.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Company}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Name}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
          <Button className="btn-danger btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
                        </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}



export {FeatureOne}

const FeatureOneList = [
    "We uphold the highest standards of honesty, transparency, and fairness in all of our auction practices.",
    "We are dedicated to offering only the finest vehicles and experiences to our bidders, ensuring that every auction delivers exceptional value and excitement.",
    "We believe in the power of connection and collaboration, and we're proud to foster a vibrant community of bidders, sellers, and vehicle enthusiasts.",
]

const FeatureTwoIcon = [
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
]

const FeatureThereeList = [
    "Where meetings come together in one.",
    "Workspaces forevery meeting.",
    "Amazing Feature.For Enhanced performance.",
]

export { FeatureOneList, FeatureTwoIcon, FeatureThereeList }

import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap"
import { Button } from "reactstrap";
import axios from "axios";
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import validation from '../Validations/Buyer-All.js';


const FeatureOne = () =>{

    const { clientId } = useParams();
    const [decryptedClientId, setDecryptedClientId] = useState('');
    const [buyerData, setBuyerData] = useState(null);
    const [error, setError] = useState(null);
    const [clientsnew, setClientsnew] = useState([]);

    const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    image1: null, 
  });
  
    useEffect(() => {
      const decryptClientId = (encryptedClientId) => {
        try {
          const decrypted = CryptoJS.AES.decrypt(
            decodeURIComponent(encryptedClientId),
            process.env.REACT_APP_ENCRYPTION_KEY
          );
          return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
          console.error('Decryption failed:', error);
          return 'Invalid Client ID';
        }
      };
  
      if (clientId) {
        const decryptedId = decryptClientId(clientId);
        setDecryptedClientId(decryptedId);
      }
    }, [clientId]);
  
    useEffect(() => {
        let isMounted = true; // This flag indicates whether the component is mounted
      
        const fetchBuyerData = async () => {
          try {
            console.log('Fetching data for:', decryptedClientId);
            const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getVehicle/${decryptedClientId}`);
            if (isMounted) { // Only update state if the component is still mounted
              console.log('API Response:', response.data);
              setBuyerData(response.data);
            }
          } catch (error) {
            if (isMounted) {
              console.error('Error fetching buyer data:', error);
              setError(error.response ? error.response.data.error : 'An error occurred');
            }
          }
        };
      
        const fetchBuyerDatanew = async () => {
            try {
              console.log('Fetching data for:', decryptedClientId);
              const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getVehiclelogs/${decryptedClientId}`);
              if (isMounted) {
                console.log('API Response:', response.data);
                if (Array.isArray(response.data)) {
                  setClientsnew(response.data);
                } else {
                  console.error('Expected an array but got:', response.data);
                  setClientsnew([]); // Default to an empty array to avoid the error
                }
              }
            } catch (error) {
              if (isMounted) {
                console.error('Error fetching logs data:', error);
                setError(error.response ? error.response.data.error : 'An error occurred');
              }
            }
          };
          
      
        if (decryptedClientId) {
          fetchBuyerData();
          fetchBuyerDatanew();
        }
      
        return () => {
          isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
        };
      }, [decryptedClientId]);
      
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    if (!buyerData) {
      return <div>Loading...</div>;
    }  
    
    var bgColors = { "light": "white",
        "greyish": "#2F2F2E"
      };

      const handleDelete = (clientId) => {
        // Make an API call to delete the client
        axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/vehicles/${clientId}`)
          .then(response => {
            // If deletion is successful, update the clients array to remove the deleted client
            setClientsnew(clientsnew.filter(client => client.Id !== clientId));
          })
          .catch(error => {
            console.error('Error deleting client:', error);
            // Handle error, such as displaying an error message
          });
      };

      
      const renderImage = (src, index) => {
        // Debugging to see the value of each image source
        // console.log(`IMG${index}:`, src);
      
        // Check if the source is not 'NULL', null, undefined, empty, or whitespace
        if (src && src.trim() !== '' && src.toUpperCase() !== 'NULL') {
          return <img src={src} alt="NA" style={{ width: '200px', height: 'auto', marginRight:10 }} />;
        }
        return null; // Return null if the image source is invalid
      };
      
     
  

  const handleFileChange = (event, imageNumber) => {
    const file = event.target.files[0];
    setValues(prev => ({ ...prev, [`image${imageNumber}`]: file }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));

   
      if (values.image1) {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('image1', values.image1);
        formData.append('Id', decryptedClientId);

        axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/updateimg1', formData)
          .then(res => {
            if (res.data === "Success") {
              setMessage("Operation was Successfull! Cover Photo was updated successfully");
              setMessageerror("");
              setIsSubmitting(false); 
            } else {
              setMessageerror("An Error Occoured! Please Recheck or Try Again.");
              setMessage("");
              event.target.reset();
              setIsSubmitting(false); 
            }
          })
          .catch(err => console.log(err));
      } else {
        setErrors(prev => ({
          ...prev,
          image1: !values.image1 ? "Please select a valid Image" : "",
        }));
    }
  }
     
      
  const refreshImages = async () => {
    try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getVehicle/${decryptedClientId}`);
        setBuyerData(response.data);
    } catch (error) {
        console.error('Error refreshing images:', error);
        setError(error.response ? error.response.data.error : 'An error occurred');
    }
};
 

    
    return(
        <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:5}}>Vehicle Management - #{buyerData.Id} / {buyerData.MAKE} {buyerData.MODEL} {buyerData.VARIENT}</h5>
                           <p style={{color:'GrayText'}}>Following views and controls are meant to review / manage individual vehicle listed in a particular auction.</p>
                         
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:5,marginTop:12}}>
                           <h6 style={{fontWeight:"bold",color:"black",marginBottom:0}}>A. Vehicle Information</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
               
                <Row>
                    <Col md="4">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        
                         
                 
                 
                  <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>SNO:</strong> {buyerData.SNO}</p>
                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>REGION:</strong> {buyerData.REGION}</p>
                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>CITY:</strong> {buyerData.CITY}</p>
                            <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>AGREMENT_NUMBER:</strong> {buyerData.AGREMENT_NUMBER}</p>
                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>CUSTOMER_NAME:</strong> {buyerData.CUSTOMER_NAME}</p>
                                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>MAKE:</strong> {buyerData.MAKE}</p>
                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>MODEL:</strong> {buyerData.MODEL}</p>
                                            <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>VARIENT:</strong> {buyerData.VARIENT}</p>
                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>REG_NO:</strong> {buyerData.REG_NO}</p>
                                                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>YOM:</strong> {buyerData.YOM}</p>
                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>ENGINE_NO:</strong> {buyerData.ENGINE_NO}</p>
                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>CHASSIS_NO:</strong> {buyerData.CHASSIS_NO}</p>
                                                          
                                                                                                     
             
                
               
                          
                            </div>
                          
                           
                           
                        </div>
                    </Col>
                    <Col  md="4">
                    <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>

                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>RC_AVAILABLE:</strong> {buyerData.RC_AVAILABLE}</p>
                                                                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>PARKING_YARD_NAME:</strong> {buyerData.PARKING_YARD_NAME}</p>
                                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>PARKING_YARD_ADDRESS:</strong> {buyerData.PARKING_YARD_ADDRESS}</p>
                                                                            <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>FUEL_TYPE:</strong> {buyerData.FUEL_TYPE}</p>
                                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>ODOMETER:</strong> {buyerData.ODOMETER}</p>
                                                                                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>QUOTE_VALIDITY:</strong> {buyerData.QUOTE_VALIDITY}</p>
                                                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>STATE:</strong> {buyerData.STATE}</p>
                                                                                           
                                                                                                       
                        </div>
                    </div>
                    </Col>
                    <Col  md="4">
                    <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>REPO_DATE:</strong> {buyerData.REPO_DATE}</p>
                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>PARKING_CHARGE_PER_DAY:</strong> {buyerData.PARKING_CHARGE_PER_DAY}</p>
                                                                                                           
                                                                                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>VEHICLE_CONDITION:</strong> {buyerData.VEHICLE_CONDITION}</p>
                                                                                                                            <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>SERVICE_BOOK_AVAILABLE:</strong> {buyerData.SERVICE_BOOK_AVAILABLE}</p>
                                                                                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>DUPLICATE_KEY_AVAILABLE:</strong> {buyerData.DUPLICATE_KEY_AVAILABLE}</p>
                                                                                                                                    <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>DOCUMENTS:</strong> {buyerData.DOCUMENTS}</p>
                                                                                                                                        <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>REMARKS:</strong> {buyerData.REMARKS}</p>
                                                                                                                                           
                                                                                                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>Id:</strong> {buyerData.Id}</p>
                                                                                                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>CONTACT_PERSON:</strong> {buyerData.CONTACT_PERSON}</p>
                                                                                                <p style={{marginBottom:0, color:"black", fontSize:'0.9rem'}}><strong>MOBILE_NO:</strong> {buyerData.MOBILE_NO}</p>
                                                                                                   
                        </div>
                    </div>
                    </Col>
                  
                </Row>
                <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:5,marginTop:12}}>
                           <h6 style={{fontWeight:"bold",color:"black",marginBottom:0}}>B. Auction Logs</h6>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        <p style={{marginTop:0, color:"black", fontSize:'0.9rem'}}><strong>TARGET PRICE:</strong> {buyerData.TARGET_PRICE} / <strong>RESERVE PRICE:</strong> {buyerData.BASE_PRICE_RESERVE_PRICE} / <strong>CURRENT BID:</strong> {buyerData.BID_START_PRICE} / <strong>LEADING BIDDER:</strong> {buyerData.BID_USER}</p>
                       
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Buyer Email</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Bid Amount</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Timestamp</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>IP Address</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clientsnew.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Useremail}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Bidamount}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Dated}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Ipaddress}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
          <Button className="btn-danger btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
                        
                        </div>
                        </div>
                        </Col>
                        </Row>
                        <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:5,marginTop:12}}>
                           <h6 style={{fontWeight:"bold",color:"black",marginBottom:0}}>C. Vehicle Image Library</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
{renderImage(buyerData.IMG1, 1)}
{renderImage(buyerData.IMG2, 2)}
{renderImage(buyerData.IMG3, 3)}
{renderImage(buyerData.IMG4, 4)}
{renderImage(buyerData.IMG5, 5)}
{renderImage(buyerData.IMG6, 6)}
{renderImage(buyerData.IMG7, 7)}
{renderImage(buyerData.IMG8, 8)}
{renderImage(buyerData.IMG9, 9)}
{renderImage(buyerData.IMG10, 10)}
{renderImage(buyerData.IMG11, 11)}
{renderImage(buyerData.IMG12, 12)}
{renderImage(buyerData.IMG13, 13)}
{renderImage(buyerData.IMG14, 14)}
{renderImage(buyerData.IMG15, 15)}
{renderImage(buyerData.IMG16, 16)}
{renderImage(buyerData.IMG17, 17)}
{renderImage(buyerData.IMG18, 18)}
{renderImage(buyerData.IMG19, 19)}
{renderImage(buyerData.IMG20, 20)}
                        </div>
                        </div>
                        </Col>
                        </Row>
                        <Row>
                <Col md="12" className="text-center" style={{marginBottom:10}}>
                    <Button color="secondary" onClick={refreshImages}>
                        Refresh Image Library
                    </Button>
                </Col>
            </Row>
                        <form className="is-alter" action="" onSubmit={handleSubmit}>
                        {message && <p style={{ padding: 5, backgroundColor: "green", color: "white", borderRadius: 2 }}>{message}</p>}
                        {messageerror && <p style={{ padding: 5, backgroundColor: "red", color: "white", borderRadius: 2 }}>{messageerror}</p>}
        <Row>
          <Col md="6">
            <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
               
               
              
                 
              <div className="form-group">
                    <div className="form-control-wrap">
                      <input onChange={(e) => handleFileChange(e, 1)}
                        type="file"
                        id="image1"
                        name="image1"
                        accept="image/*" 
                        className="form-control-lg form-control"
                      />
                      {errors.image1 && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.image1}</span>}
                    </div>
                  </div>
              </div>

            </div>
          </Col>
          <Col  md="6">
          <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
               
                <div className="form-group">
                    <Button type="submit" disabled={isSubmitting} color="primary" size="lg" className="btn-block">
                    {isSubmitting ? 'Please Wait...' : 'Upload / Update Cover Photo'}
                    </Button>
                    
                  </div>    </div></div>
          </Col>
        </Row>
        </form>
            </Container>
        </div>
    )
}



export {FeatureOne}

import React from "react"
import BannerOne from "./Dashboard/BannerOne"
import { FeatureOne } from "./Dashboard/Settings"
import FooterOne from "./Dashboard/FooterOne"

const Settings = () =>{
    return(
    <div className="nk-main">
        <BannerOne className="has-header-main-s1 bg-grad-a mb-5 mb-sm-6 mb-md-7" id="#home" />
        <FeatureOne className="section-feature pb-0" id="#feature"/>
        <FooterOne className="bg-indigo is-dark" id="#footer" />
    </div>
    )
}


export default Settings
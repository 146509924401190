import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Service, ServiceIcon, ServiceText } from "../../components/service/Service"
import { BarChart, Cloud, Dashboard } from "../../components/svg/Icon"
import {Section, SectionContant, SectionHead} from "../../layout/section/Section"

const ServieOne = (props) =>{
    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row>
                    <Col xs="10" sm="7" md="5">
                        <SectionHead>
                            <h2 className="title">Our App provides various Live Auction Services</h2>
                        </SectionHead>
                    </Col>
                </Row>
                <SectionContant>
                    <Row className="g-gs">
                        <Col sm="6" lg="4">
                            <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <BarChart />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">Real-Time Auctions</h4>
                                    <p>The Users can participate in live auctions along with the details such as vehicle name, vehicle photos, remaining auction time, bidding amount counter, bidder name, and more.</p>
                                </ServiceText>
                            </Service>
                        </Col>
                        <Col sm="6" lg="4">
                            <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <Dashboard />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">User-friendly Interface</h4>
                                    <p>Users can create the post, join the live action and bid on the cars, sort, and filter posts, post details, make an offer, and perform various tasks seamlessly.</p>
                                </ServiceText>
                            </Service>
                        </Col>
                        <Col sm="6" lg="4">
                            <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <Cloud />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">Private Auction Rooms</h4>
                                    <p>Users can join the auction room and a list of vehicle posts for bidding will be displayed. Users can search, sort, and filter the list of auction rooms.</p>
                                </ServiceText>
                            </Service>
                        </Col>
                    </Row>
                </SectionContant>
            </Container>
        </Section>
    )
}

export default ServieOne
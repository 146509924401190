import React, {useState} from "react"
import { Col, Row, Container, Button } from "reactstrap"
import { Logo } from "../components/logo/Logo"

import LogoDrak2x from '../Resc/header-white.png'
import LogoLight2x from '../Resc/header-white.png'   
import { Link } from "react-router-dom";
import {useCookies } from 'react-cookie';


const FooterOne = () =>{

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [cookies, setCookies, removeCookie] = useCookies(['token']);
    const myCookieValue = cookies.token;

  function logout() {
    removeCookie('token');
    setIsAuthenticated(false);
    console.log("testing");
  }
  


    var bgColors = { "dark": "#434342"};
    return(
        <div style={{backgroundColor:bgColors.dark, marginTop:"2%"}}>
            <Container>
                <Row className="g-3 align-items-center justify-content-md-between py-2 py-md-3">
                    <Col md="3">
                        <div className="footer-logo">
                            <Logo to="/"
                            dark ={LogoDrak2x}
                            light ={LogoLight2x}
                            />
                        </div>
                    </Col>
                    <Col md="9" className="d-flex justify-content-md-end">

                        {/* <LinkInline className="gx-4" data={LinkData} /> */}

               
                <Link className="gx-4" style={{marginLeft: 0, marginRight: 20, color: "whitesmoke"}} to="/">
                   <b>Auth User</b>: {myCookieValue}
                </Link>

                <Button className="btn-danger btn-small" style={{marginLeft: 0, marginRight: 0, color: "whitesmoke", fontWeight: "bolder", marginTop: -7}} type="submit" onClick={logout}>
                  Secure Logout
                </Button>

                    </Col>
                </Row>
              
            </Container>
        </div>
    )
}

export default FooterOne

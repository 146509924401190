import React, { useState } from "react"
import { Collapse } from "reactstrap"

const Accordion = ({ className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  return (
    <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
          <h6 className="title">Are the details of damages not listed in vehicle details?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
          <div className="accordion-inner">
            <p>It is not possible for us to access the extent of damages to the vehicles which may have occurred during the time the vehicle was lying with the Insurance companies / manufactures and the details of repairs if any, done by them.  Insurance companies / manufactures do not provide to us are to our affiliated dealers with such kind of information including damage parts etc. The vehicles are disposed off by the insurance companies / manufactures on as is and where is basis.</p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
          <h6 className="title">How much is the security deposit?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
          <div className="accordion-inner">
            <p>
            The Security Deposit to be given by the intending bidder is to be Rs. 20000/- payable by Demand Draft or Cash in the name of BidToWin Pvt Ltd.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("3")}>
          <h6 className="title">How do I pay for the Vehicles?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
          <div className="accordion-inner">
            <p>
            Full Payment Due: 48 hours from sale confirmation notice. Once your offer is accepted by one of our affiliated dealers, you will notify through mail / SMS. It's your responsibility to check your E-mail / SMS or your account on our official app. All accounts with payments after specified due date will be charged Rs. 100 per day late fee for every item.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("4")}>
          <h6 className="title">How Used Vehicle are Sold?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "4" ? true : false}>
          <div className="accordion-inner">
            <p>
            All the vehicles are sold on "As is where is basis". Reasons related to Year of Manufacture mismatch / parts missing etc. will not be entertained. Buyer is solely responsible for his own bid and will be liable to gather all correct information about the vehicles. Please rely on your inspection for correct information/evaluation of vehicle. We are giving information which are provided through seller and the buyer can directly connect to sellers.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "5" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("5")}>
          <h6 className="title">While registering I got an alert saying, "An account with this email address already exists".</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "5" ? true : false}>
          <div className="accordion-inner">
          <p>This happens only when registration already exists with the email id provided. In case you have forgotten the password, you can retrieve your password through a simple process and in case if you could not complete the registration process because you did not receive the verification link, please mail us at info@bidtowin.in with subject as "verification mail not received".</p>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Accordion;

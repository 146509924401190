function validation(values)
{
    let error = {}
    


        if(values.company === "")
        {
            error.company = "Field cannot be empty"
        }
        else
        {
            error.company = ""
        }


        

        return error;

}

export default validation;
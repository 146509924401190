import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap"
import { Button } from "reactstrap";

import axios from "axios";

import validation from '../Validations/Login-Email.js';
import validationnew from '../Validations/Login-Password.js';
import cookies, {useCookies} from "react-cookie";


const FeatureOne = () =>{

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [message, setMessage] = useState("");
    const [messageerror, setMessageerror] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [errors, setErrors] = useState({});
    const [errorsnew, setErrorsnew] = useState({});
    const [cookies, setCookie] = useCookies(["token"]);
    
    
    var bgColors = { "light": "white",
        "greyish": "#2F2F2E"
      };

    
      
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form Submitted");
        setErrors(validation(email));
        setErrorsnew(validationnew(password));
    
        console.log("Email:", email);
        console.log("Password:", password);
        console.log("Errors:", errors);
        console.log("New Errors:", errorsnew);
    
        if (errors.email === "" && errorsnew.password === "") {
            setIsSubmitting(true); // Disable the button immediately
            const userData = {
                email,
                password
            };
            console.log("User Data to be sent:", userData);
    
            axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/administratorupdate', userData)
                .then(res => {
                    console.log("Response from server:", res.data);
    
                    if (res.data === "Success") {
                        setCookie("token", email, { path: "/" });
                        setMessage("Operation Success! Credentials were updated successfully!");
                        setMessageerror("");
                        event.target.reset();
                        setIsSubmitting(false);
                    } else {
                        setMessageerror("Sorry! An error occurred please try again later.");
                        setMessage("");
                        setIsSubmitting(false);
                    }
                })
                .catch(err => {
                    console.error("Axios request failed:", err);
                    setMessageerror("An error occurred. Please try again later.");
                    setIsSubmitting(false);
                });
        }
    }
    
    
    return(
        <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:5}}>Administrator Credentials</h5>
                           <p style={{color:'GrayText'}}>Use the following form control to update administrator credentials in case the account has been compromised or requires a periodic credentials update.</p>
                           {message && <p style={{padding:5, backgroundColor:"green", color:"white", borderRadius:2}}>{message}</p>}
                           {messageerror && <p style={{padding:5, backgroundColor:"red", color:"white", borderRadius:2}}>{messageerror}</p>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <form className="is-alter" action="" onSubmit={handleSubmit}>
                <Row>
                    <Col md="6">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        
                         
                 <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email Address
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter Email Address"
                        className="form-control-lg form-control"
                      />
                      {errors.email && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.email}</span>}
                    </div>
                  </div>
                 
               
             
                
               
                          
                            </div>
                          
                           
                           
                        </div>
                    </Col>
                    <Col  md="6">
                    <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Secure Password
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    
                    <input onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      placeholder="Enter Secure Password"
                      className="form-control-lg form-control"
                    /> {errorsnew.password && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errorsnew.password}</span>}
                  </div>
                </div>
                        </div>
                    </div>
                    </Col>
                    <Col  md="12">
                    <div className="form-group">
                    <Button type="submit" disabled={isSubmitting} color="primary" size="lg" className="btn-block">
                    {isSubmitting ? 'Please Wait...' : 'Update Login Credentials'}
                    </Button>
                  </div>

                    </Col>
                </Row>
                </form>
            </Container>
        </div>
    )
}



export {FeatureOne}

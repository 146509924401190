import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap"
import { Button } from "reactstrap";
import axios from "axios";


const FeatureOne = () =>{

  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getRegistrationsData')
      .then(response => {
        // Store the fetched data in the state
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Run this effect only once when the component mounts


    

  var bgColors = { "light": "white",
  "greyish": "#2F2F2E"
};

const handleDelete = (clientId) => {
  // Make an API call to delete the client
  axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/registrations/${clientId}`)
    .then(response => {
      // If deletion is successful, update the clients array to remove the deleted client
      setClients(clients.filter(client => client.Id !== clientId));
    })
    .catch(error => {
      console.error('Error deleting record:', error);
      // Handle error, such as displaying an error message
    });
};

    
    return(
        <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:5}}>Buyer Interests</h5>
                           <p style={{color:'GrayText'}}>The following datalist helps to keep track of all buyers interested in registering on the network.</p>
                           
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    
                    <Col  md="12">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30,backgroundColor:bgColors.light}}>
                     
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Company</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Contact Person</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Contact No.</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Email Address</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clients.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Company}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Name}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Contact}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Email}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
          <Button className="btn-danger btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

                        </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}



export {FeatureOne}

import React from "react"
import BannerOne from "./Rescomponents/BannerOne2"
import { FeatureOne } from "./Rescomponents/FeatureOne2"
import FooterOne from "./Rescomponents/FooterOne"

const IndexOne = () =>{
    return(
    <div className="nk-main">
        <BannerOne className="has-header-main-s1 bg-grad-a mb-5 mb-sm-6 mb-md-7" id="#home" />
        <FeatureOne className="section-feature pb-0" id="#feature"/>
        <FooterOne className="bg-indigo is-dark" id="#footer" />
    </div>
    )
}


export default IndexOne

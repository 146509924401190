import React from "react"

const BarChart = (props) =>{
    return(
        <svg fill="currentColor" viewBox="0 0 512 512">
            <path d="M488.4 492h-21.9V173.5c0-14.8-12.1-26.9-26.9-26.9h-49c-14.8 0-26.9 12.1-26.9 26.9V492H308V317.8c0-14.8-12.1-26.9-26.9-26.9h-49c-14.8 0-26.9 12.1-26.9 26.9V492h-55.7v-90.2c0-14.8-12.1-26.9-26.9-26.9h-49c-14.8 0-26.9 12.1-26.9 26.9V492H23.6c-5.5 0-10 4.5-10 10s4.5 10 10 10h464.8c5.5 0 10-4.5 10-10s-4.5-10-10-10zm-358.9 0H66.7v-90.2c0-3.8 3.1-6.9 6.9-6.9h49c3.8 0 6.9 3.1 6.9 6.9V492zm158.5 0h-62.8V317.8c0-3.8 3.1-6.9 6.9-6.9h49c3.8 0 6.9 3.1 6.9 6.9V492zm158.5 0h-62.8V173.5c0-3.8 3.1-6.9 6.9-6.9h49c3.8 0 6.9 3.1 6.9 6.9V492zm19.9-481.5c.1-2.7-.8-5.5-2.9-7.6s-4.9-3-7.6-2.9H395c-5.5 0-10 4.5-10 10s4.5 10 10 10h37.4l-98.9 98.9-37.3-37.3c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1.1-7.1 2.9L102.3 261.3c-3.9 3.9-3.9 10.2 0 14.1 2 2 4.5 2.9 7.1 2.9s5.1-1 7.1-2.9l172.7-172.7 37.3 37.3c3.9 3.9 10.2 3.9 14.1 0L446.5 34.1V68c0 5.5 4.5 10 10 10s10-4.5 10-10V11c0-.2-.1-.3-.1-.5z"></path>
            <circle cx="75.6" cy="303.3" r="10"></circle>
        </svg>
    )
}

export default BarChart

import React, { useState, useEffect } from 'react'

import LogoDrak2x from '../Resc/header-black.png'
import LogoLight2x from '../Resc/header-white.png'

import Menu from '../layout/menu/Menu'
import MobileMenu from '../layout/menu/MobileMenu'
import {Logo} from "../components/logo/Logo"
import { Col, Container, Row } from "reactstrap"
import {Header, HeaderWrap, HeaderMain} from "../layout/header/Header2"
import {Link, Button} from "../components/button/Button"
import { HeaderCaption} from '../components/headerCaption/HeaderCaption'

import {BannerOneData} from './BannerData'


const BannerOne = (props) =>{
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
        setOffset(window.pageYOffset)
        }
        window.scrollTo(0,0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
        setMobileView(true);
        } else {
        setMobileView(false);
        }
    };


    return(
    <Header className={props.className && props.className} id={props.id && props.id}>
        <HeaderMain className={`header-main header-main-s1 is-sticky on-dark is-transparent ${offset > 0 ? "has-fixed" : ""}`}>
            <Container className="header-container">
                <HeaderWrap>
                    <div className="header-logo">
                        <Logo to="/"
                        dark ={LogoDrak2x}
                        light ={LogoLight2x}
                        />
                    </div>
                    <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                        <button className={`menu-toggler ${toggle === true ? "active" : ""}`}> 
                            <em className="menu-on icon ni ni-menu"></em>
                            <em className="menu-off icon ni ni-cross"></em>
                        </button>
                    </div>
                    <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}> 
                        {!mobileView ? <Menu className="ms-lg-auto" data={BannerOneData} /> : <MobileMenu data={BannerOneData}/>}
                        <ul className="menu-btns">
                            <li>
                            <a
  href="https://play.google.com/store/apps/details?id=com.lyndatasystemsofficial.Bidtowin&hl=en"
  target="_blank"
  rel="noreferrer"
  
>
  <Button className="btn-primary btn-lg">
    Download App
  </Button>
</a>
                            </li>
                        </ul>
                    </nav>
                    {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
                </HeaderWrap>
            </Container>
        </HeaderMain>
        <div className="my-auto is-dark" style={{paddingTop: "6rem", paddingBottom: "2.75rem"}}>
            <div className="container">
                <Row className="row justify-content-center text-center">
                    <Col lg="7" md="10">
                        <HeaderCaption>
                            <h3>Safegaurding Your Privacy</h3>
                        </HeaderCaption>
                    </Col>
                </Row>
            </div>
        </div>
    </Header>
    )
}


export default BannerOne

function validation(values)
{
    let error = {}
    


        if(values.name === "")
        {
            error.name = "Field cannot be empty"
        }
        else
        {
            error.name = ""
        }

        if(values.enddate === "")
            {
                error.enddate = "Field cannot be empty"
            }
            else
            {
                error.enddate = ""
            }

            if(values.endtime === "")
                {
                    error.endtime = "Field cannot be empty"
                }
                else
                {
                    error.endtime = ""
                }

                if(values.client === "")
                    {
                        error.client = "Please select a valid Client"
                    }
                    else
                    {
                        error.client = ""
                    }

                    if(values.category === "")
                        {
                            error.category = "Please select a valid Category"
                        }
                        else
                        {
                            error.category = ""
                        }
        

        return error;

}

export default validation;
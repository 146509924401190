import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Col, Container, Row } from "reactstrap";
import { Button } from "reactstrap";

const FeatureOne = () => {
  const { clientId } = useParams();
  const [decryptedClientId, setDecryptedClientId] = useState('');
  const [buyerData, setBuyerData] = useState(null);
  const [error, setError] = useState(null);
  const [clientsnew, setClientsnew] = useState([]);

  useEffect(() => {
    const decryptClientId = (encryptedClientId) => {
      try {
        const decrypted = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptedClientId),
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Decryption failed:', error);
        return 'Invalid Client ID';
      }
    };

    if (clientId) {
      const decryptedId = decryptClientId(clientId);
      setDecryptedClientId(decryptedId);
    }
  }, [clientId]);

  useEffect(() => {
    let isMounted = true; // This flag indicates whether the component is mounted
  
    const fetchBuyerData = async () => {
      try {
        console.log('Fetching data for:', decryptedClientId);
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyer/${decryptedClientId}`);
        if (isMounted) { // Only update state if the component is still mounted
          console.log('API Response:', response.data);
          setBuyerData(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching buyer data:', error);
          setError(error.response ? error.response.data.error : 'An error occurred');
        }
      }
    };
  
    const fetchBuyerDatanew = async () => {
        try {
          console.log('Fetching data for:', decryptedClientId);
          const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getVehiclelogsnew/${decryptedClientId}`);
          if (isMounted) {
            console.log('API Response:', response.data);
            if (Array.isArray(response.data)) {
              setClientsnew(response.data);
            } else {
              console.error('Expected an array but got:', response.data);
              setClientsnew([]); // Default to an empty array to avoid the error
            }
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching logs data:', error);
            setError(error.response ? error.response.data.error : 'An error occurred');
          }
        }
      };
      
  
    if (decryptedClientId) {
      fetchBuyerData();
      fetchBuyerDatanew();
    }
  
    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  }, [decryptedClientId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!buyerData) {
    return <div>Loading...</div>;
  }

  var bgColors = { "light": "white",
    "greyish": "#2F2F2E"
  };

  const handleDelete = (clientId) => {
    axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/auctionlogs/${clientId}`)
      .then(response => {
        setClientsnew(clientsnew.filter(client => client.Id !== clientId));
      })
      .catch(error => {
        console.error('Error deleting client:', error);
      });
  };

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 5 }}>
                  Reg. Buyer Profile - #{buyerData.Id} / {decryptedClientId}
                </h5>
                <p style={{ color: 'GrayText' }}>
                  The following views / controls help keep track of all buyer activities and registered data.
                </p>
              </div>
              <div>
                <ul>
                  
                 
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
                    <Col md="5">
              <div style={{padding:5, border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>  

              <h6 style={{fontWeight:"bold",color:"black",marginBottom:15, marginLeft:7, marginTop:10}}>A. Registration Information</h6>

                  <p style={{marginBottom:0, color:"black", marginLeft:7}}><strong>Full Name:</strong> {buyerData.Name}</p>
                  <p style={{marginBottom:0, color:"black", marginLeft:7}}><strong>Pan Number:</strong> {buyerData.Panno}</p>
                  <p style={{marginBottom:0, color:"black", marginLeft:7}}><strong>Contact No:</strong> {buyerData.Contact}</p>
                  <p style={{marginBottom:0, color:"black", marginLeft:7, marginTop:10}}><strong style={{color:"red"}}>Email:</strong> {buyerData.Email}</p>
                  <p style={{marginBottom:0, color:"black", marginLeft:7}}><strong style={{color:"red"}}>Password:</strong> {buyerData.Pass}</p>
</div>
  <div style={{padding:5, border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light, marginTop:10}}>  

  <h6 style={{fontWeight:"bold",color:"black",marginBottom:15, marginLeft:7, marginTop:10}}>B. Uploaded Files</h6>
                  <div style={{
  padding: 5,
  border: "none",
  borderRadius: "5px",
  backgroundColor: bgColors.light,
  display: 'grid',
  gridTemplateColumns: '1fr 100px',
  gap: '10px',
  marginTop: '20px'
}}>
  <div>
    <p style={{ margin: 0, color: "black" }}><strong>a. Uploaded PAN</strong></p>
    <p style={{ margin: 0, color: "grey", fontSize:11 }}>Use Right Click on the image to Download</p>
  </div>
  <img src={buyerData.Image1} alt="Image1" style={{ width: '100px', height: 'auto' }} />
  
  <div>
    <p style={{ margin: 0, color: "black" }}><strong>b. Uploaded Aadhar</strong></p>
    <p style={{ margin: 0, color: "grey", fontSize:11 }}>Use Right Click on the image to Download</p>
  </div>
  <img src={buyerData.Image2} alt="Image2" style={{ width: '100px', height: 'auto' }} />
  
  <div>
    <p style={{ margin: 0, color: "black" }}><strong>c. Payment Receipt</strong></p>
    <p style={{ margin: 0, color: "grey", fontSize:11 }}>Use Right Click on the image to Download</p>
  </div>
  <img src={buyerData.Image3} alt="Image3" style={{ width: '100px', height: 'auto' }} />
  
  <div>
    <p style={{ margin: 0, color: "black" }}><strong>d. Cancelled Cheque</strong></p>
    <p style={{ margin: 0, color: "grey", fontSize:11 }}>Use Right Click on the image to Download</p>
  </div>
  <img src={buyerData.Image4} alt="Image4" style={{ width: '100px', height: 'auto' }} />
</div>
              </div>
              </Col>
              <Col md="7">
              <div style={{padding:5, border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>  

              <h6 style={{fontWeight:"bold",color:"black",marginBottom:15, marginLeft:7, marginTop:10}}>C. Activity Tracker</h6>

              <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>AID</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>VID</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Bid Amount</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Timestamp</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>IP Address</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clientsnew.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
      <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Auctionid}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Vehicleid}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Bidamount}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Dated}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Ipaddress}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
          <Button className="btn-danger btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
</div>
              </Col>
              </Row>


      </Container>
    </div>
  );
};

export { FeatureOne };
